<template>
  <v-dialog
    v-model="myValue"
    width="fit-content"
  >
    <v-card 
      class="dialog-create-contract-card"
      min-width="360"
    >
      <v-card-title class="pb-3">
        <span class="card-title-text px-2 pt-1">Create New Request</span>
        <v-spacer></v-spacer>
        <v-btn text small icon class="my-1" @click="closeMe">
            <v-icon>mdi-close</v-icon>
          </v-btn>
      </v-card-title>
      <v-card-text class="card-content px-4 pt-1">
        <v-btn text block class="contract-type-btn pl-3 my-1" color="grey darken-1" @click="clickClientProjectContract">
          <v-icon class="mr-3" size="24">mdi-file-document-outline</v-icon>
          <span class="mr-3">Client Project Contract</span>
        </v-btn>
        <v-btn text block class="contract-type-btn pl-3 my-1" color="grey darken-1" @click="clickVendorContract">
          <v-icon class="mr-3" size="24">mdi-file-account</v-icon>
          <span class="mr-3">Vendor Contract</span>
        </v-btn>
        <v-btn text block class="contract-type-btn pl-3 my-1" color="grey darken-1" @click="clickOtherLegalRequests">
          <v-icon class="mr-3" size="24">mdi-file-table</v-icon>
          <span class="mr-3">Other Legal Requests</span>
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  data () {
    return {
      myValue: false
    }
  },
  methods:{
    closeMe () {
      this.myValue = false
    },
    clickClientProjectContract () {
      this.$emit('click-client-project-contract')
    },
    clickVendorContract () {
      this.$emit('click-vendor-contract')
    },
    clickOtherLegalRequests () {
      this.$emit('click-other-legal-requests')
    }
  },
  mounted () {
    // preset myValue
    if (this.myValue !== this.value) {
      this.myValue = this.value
    }
  },
  watch: {
    value: {
      handler (nv, ov) {
        if (nv !== ov) {
          this.myValue = this.value
        }
      }
    },
    myValue: {
      handler (nv, ov) {
        if (nv !== ov) {
          this.$emit('change', nv)
        }
      }
    }
  }
}
</script>

<style lang="sass">
  .dialog-create-contract-card
    .card-title-text
      font-size: 18px

    .card-content
      text-align: left

      .contract-type-btn
        .v-btn__content
          justify-content: flex-start !important

        &:hover:before
          background-color: rgba(0, 0, 0, .6)
</style>
